@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

@font-face {
  font-family: "Calibri-Z";
  src: url("./assets/fonts/calibri/CALIBRIZ.TTF");
}

@font-face {
  font-family: "Calibri-Bold";
  src: url("./assets/fonts/calibri/calibri-bold.ttf");
}

html {
  overscroll-behavior: none;
}

* {
  box-sizing: border-box;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
}

input {
  user-select: auto;
  -webkit-user-select: auto;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  background-color: black;
}
