@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@font-face {
  font-family: "Calibri-Z";
  src: url(/static/media/CALIBRIZ.29166a7a.TTF);
}

@font-face {
  font-family: "Calibri-Bold";
  src: url(/static/media/calibri-bold.2a395801.ttf);
}

html {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

* {
  box-sizing: border-box;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
}

input {
  -ms-user-select: auto;
      user-select: auto;
  -webkit-user-select: auto;
}

body {
  margin: 0;
  padding: 0;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  background-color: black;
}

.animated-button {
  background: linear-gradient(-30deg, #0b1b3d 50%, #08142b 50%);
  padding: 1.5vw 3vw;
  margin: 1vw;
  display: inline-block;
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e0f7;
  font-size: 1.25vw;
  font-weight: bold;
  letter-spacing: 0.5vw;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.animated-button::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8592ad;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button:hover::before {
  opacity: 0.2;
}

.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(8, 20, 43, 0), #2662d9);
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(8, 20, 43, 0), #2662d9);
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #2662d9);
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #2662d9);
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

